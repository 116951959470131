import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { CARDLAYOUT } from 'utils/layout/CardLayout'
import {CardSeven, CardFive, CardFour, CardOne, CardSix, CardThree, CardTwo, CardEight, CardNine, CardTen} from './cards'
import MobileCardDecrement from './MobileCardDecrement'

const CardStack = observer(() => {
    const {uiStore: {windowWidth, cardSlideDistance}} = useStore();

    return (
        <React.Fragment>
            <MobileCardDecrement/>
            <CardOne/>
            <CardTwo/>
            <CardThree/>
            <CardFour/>
            <CardFive/>
            <CardSix/>
            <CardSeven/>
            <CardEight/>
            <CardNine/>
            <CardTen/>

            <style jsx global>{`
                .card {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    // will-change: transform;
                    contain: strict;
                    backface-visibility: hidden;
                    z-index: 12;
                    width: ${CARDLAYOUT.width}px;
                    height: ${CARDLAYOUT.height}px;
                }
                
                .card__contents {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 40px;
                }

                .card--1 {
                    z-index: 12;
                    width: 540px;
                    height: 650px;
                }
                .card--2 {
                    z-index: 11;
                }
                .card--3 {
                    z-index: 10;
                }
                .card--4 {
                    z-index: 9;
                }
                .card--5 {
                    z-index: 8;
                }
                .card--6 {
                    z-index: 7;
                }
                .card--7 {
                    z-index: 6;
                }

                .card--8 {
                    z-index: 5;
                    width: ${CARDLAYOUT.cards.cardEight.width}px;
                    height: ${CARDLAYOUT.cards.cardEight.height}px;
                }

                .card--9 {
                    z-index: 4;
                    width: 500px;
                    height: 650px;
                }

                .card--10 {
                    z-index: 3;
                    width: ${CARDLAYOUT.cards.cardTen.width}px;
                    height: ${CARDLAYOUT.cards.cardTen.height}px;
                }

                @media (max-width: 769px) {
                    .card {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: ${windowWidth}px;
                        height: ${windowWidth / .7}px;
                        transition: transform .4s ease;
                    }

                    .card__contents {
                        padding: 20px 30px;
                        padding-top: 20px;
                    }

                    .card-shape {
                        margin-left: 10px;
                    }

                    .card.toggled {
                        transform: translate3d(${-1 * (windowWidth - 30)}px, ${0}px, 0);
                        pointer-events: none;
                    }
                                    
                    .card--1 {
                        top: ${CARDLAYOUT.verticalDistanceBase}px;
                        transform: rotate(${Math.random()}deg);
                    }

                    .card--2 {
                        top: ${CARDLAYOUT.verticalDistanceBase + CARDLAYOUT.verticalDistanceStack * 1}px;
                        transform: rotate(${-1 * Math.random()}deg);
                    }

                    .card--3 {
                        top: ${CARDLAYOUT.verticalDistanceBase + CARDLAYOUT.verticalDistanceStack * 2}px;
                        transform: rotate(${Math.random()}deg);
                    }

                    .card--4 {
                        top: ${CARDLAYOUT.verticalDistanceBase + CARDLAYOUT.verticalDistanceStack * 4}px;
                        transform: rotate(${-.8}deg);
                    }

                    .card--5 {
                        top: ${CARDLAYOUT.verticalDistanceBase + CARDLAYOUT.verticalDistanceStack * 4}px;
                        transform: rotate(${-.8}deg);
                    }

                    .card--6 {
                        top: ${CARDLAYOUT.verticalDistanceBase + CARDLAYOUT.verticalDistanceStack * 5}px;
                        transform: rotate(${-1 * Math.random()}deg);
                    }

                    .card--7 {
                        top: ${CARDLAYOUT.verticalDistanceBase + CARDLAYOUT.verticalDistanceStack * 6}px;
                        transform: rotate(${Math.random()}deg);

                    }

                    .card--8 {
                        top: ${CARDLAYOUT.verticalDistanceBase + (CARDLAYOUT.verticalDistanceStack * 7) + 100}px;
                        height: ${CARDLAYOUT.cards.cardEight.mobileHeight}px;
                        transform: rotate(${-1 * Math.random()}deg);

                    }
                    
                    .card--8.toggled {
                        // transform: translate3d(${-1 * (windowWidth - 30)}px, ${(cardSlideDistance) + ((windowWidth / .75) -  CARDLAYOUT.cards.cardEight.mobileHeight)}px, 0);
                    }

                    .card--9 {
                        top: ${CARDLAYOUT.verticalDistanceBase + CARDLAYOUT.verticalDistanceStack * 8}px;
                        transform: rotate(${Math.random()}deg);

                    }

                    .card--10 {
                        top: ${CARDLAYOUT.verticalDistanceBase + (CARDLAYOUT.verticalDistanceStack * 9) + 100}px;
                        height: ${CARDLAYOUT.cards.cardTen.mobileHeight}px;
                        transform: rotate(${-1 * Math.random()}deg);

                    }

                    .card--10.toggled {
                        // transform: translate3d(${-1 * (windowWidth - 30)}px, ${(cardSlideDistance) + ((windowWidth / .75) -  CARDLAYOUT.cards.cardTen.mobileHeight)}px, 0);
                    }
                }

            `}</style>
        </React.Fragment>
    )
})

export default CardStack