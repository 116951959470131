import firebase from "firebase/app";
import { COLLECTION_ID, FBDatabase } from "./firebase-client"

type TextData = {
    text: string;
    qid: string;
    type?: string;
    createdAt?: firebase.firestore.Timestamp;
}

const FBSubmitTextInput = (data: TextData) => {
    data.type = "Text";
    data.createdAt = firebase.firestore.Timestamp.now();

    return FBDatabase.collection(COLLECTION_ID)
        .add(data)
        .catch(() => {
            console.error('error saving text input', data)
        });
}

export default FBSubmitTextInput