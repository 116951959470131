import { action, makeObservable, observable, computed } from "mobx"
import RootStore from "./RootStore"
import debounce from 'lodash/debounce'

class UIStore {
    rootStore: RootStore;
    scrollY: number;
    // cardOffset: number;
    isMobile: boolean;
    scrollInitialized: boolean;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore
        this.scrollY = 0;
        this.isMobile = window.innerWidth < 769
        this.scrollInitialized = false
    }


    @observable windowHeight = window.innerHeight;
    @observable windowWidth = window.innerWidth;

    // @observable scrollY = 0;
    @observable mobile__cardOne__toggled = false;
    @observable mobile__cardTwo__toggled = false;
    @observable mobile__cardThree__toggled = false;
    @observable mobile__cardFour__toggled = false;

    @observable mobile__cardFive__toggled = false;
    @observable mobile__cardSix__toggled = false;
    @observable mobile__cardSeven__toggled = false;
    @observable mobile__cardEight__toggled = false;
    @observable mobile__cardNine__toggled = false;
    @observable mobile__cardTen__toggled = false;

    mobileCardIdx = 0;

    @observable tweens__cardOne = 0;
    @observable tweens__cardTwo = 0;
    @observable tweens__cardThree = 0;
    @observable tweens__cardFour = 0;

    @observable tweens__cardFive = 0;
    @observable tweens__cardSix = 0;
    @observable tweens__cardSeven = 0;
    @observable tweens__cardEight = 0;

    @observable tweens__cardNine = 0;
    @observable tweens__cardTen = 0;

    @observable ui__showSubDescription = false;

    // @observable ui__showSignUp = false;
    
    scrollTicking = false;

    @action.bound initScrollListener() {
        if (this.scrollInitialized) return
        this.scrollInitialized = true;

        document.addEventListener("scroll", this.handleScroll.bind(this), {passive: true});
    }

    @computed get cardSlideDistance() {
        return (-1 * ((this.windowHeight / 2) + 40));
    }

    initResizeListener() {
        document.addEventListener("resize", debounce(this.handleResize, 300))
    }

    handleScroll() {
        if (this.isMobile) return

        this.scrollY = window.scrollY;
        this.requestScrollTick();
    }

    requestScrollTick() {
        if(!this.scrollTicking) {
			requestAnimationFrame(this.updateScrollPosition);
		}
        
		this.scrollTicking = true;
    }

    @action.bound handleResize() {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;

        this.isMobile = window.innerWidth < 769;
    }



    
    @action.bound updateScrollPosition() {
        this.scrollTicking = false;
        const overlap = 100;
        // this.scrollY = window.scrollY;

        this.tweens__cardOne = Math.min(this.scrollY / (this.windowHeight), 1);
        this.tweens__cardTwo = Math.min(Math.max(this.scrollY - (this.windowHeight - overlap), 0)  / this.windowHeight, 1);

        this.tweens__cardThree = Math.min(Math.max(this.scrollY - ((this.windowHeight - overlap) * 2), 0)  / this.windowHeight, 1);
        this.tweens__cardFour = Math.min(Math.max(this.scrollY - ((this.windowHeight - overlap) * 3), 0)  / this.windowHeight, 1);
        this.tweens__cardFive = Math.min(Math.max(this.scrollY - ((this.windowHeight - overlap) * 4), 0)  / this.windowHeight, 1);
        this.tweens__cardSix = Math.min(Math.max(this.scrollY - ((this.windowHeight - overlap) * 5), 0)  / this.windowHeight, 1);
        this.tweens__cardSeven = Math.min(Math.max(this.scrollY - ((this.windowHeight - overlap) * 6), 0)  / this.windowHeight, 1);
        this.tweens__cardEight = Math.min(Math.max(this.scrollY - ((this.windowHeight - overlap) * 7), 0)  / this.windowHeight, 1);
        this.tweens__cardNine = Math.min(Math.max(this.scrollY - ((this.windowHeight - overlap) * 8), 0)  / this.windowHeight, 1);
        this.tweens__cardTen = Math.min(Math.max(this.scrollY - ((this.windowHeight - overlap) * 9), 0)  / this.windowHeight, 1);
    
        if (!this.ui__showSubDescription && this.tweens__cardTen > .6) {
            this.ui__showSubDescription = true;
        } else if (this.ui__showSubDescription && this.tweens__cardTen < .6) {
            this.ui__showSubDescription = false;
        }
    }

    @action.bound scrollToCard(cardNum: number) {
        if (this.isMobile) return

        const distance = ((cardNum - 1 )* (this.windowHeight - 100)) + 0;

        window.scrollTo({
            top: distance,
            left: 0,
            behavior: 'smooth'
        });
    }

    @action.bound toggleCard(cardName: string) {
        this[`mobile__${cardName}__toggled`] = !this[`mobile__${cardName}__toggled`];
    }

    @action.bound incrementCardsMobile() {

        if (this.mobileCardIdx > 9) return

        this.mobileCardIdx++;
        this.updateMobileCardToggles()
    }


    @action.bound decrementCardsMobile() {
        if (this.mobileCardIdx === 0) return

        this.mobileCardIdx--;
        this.updateMobileCardToggles()
    }
    
    @action.bound scrollToEndMobile() {
        this.mobileCardIdx = 10;
        this.updateMobileCardToggles()
    }

    @action.bound updateMobileCardToggles() {
        this.mobile__cardOne__toggled = this.mobileCardIdx > 0 ? true: false
        this.mobile__cardTwo__toggled = this.mobileCardIdx > 1 ? true: false
        this.mobile__cardThree__toggled = this.mobileCardIdx > 2 ? true: false
        this.mobile__cardFour__toggled = this.mobileCardIdx > 3 ? true: false
        this.mobile__cardFive__toggled = this.mobileCardIdx > 4 ? true: false

        this.mobile__cardSix__toggled = this.mobileCardIdx > 5 ? true: false
        this.mobile__cardSeven__toggled = this.mobileCardIdx > 6 ? true: false
        this.mobile__cardEight__toggled = this.mobileCardIdx > 7 ? true: false
        this.mobile__cardNine__toggled = this.mobileCardIdx > 8 ? true: false
        this.mobile__cardTen__toggled = this.mobileCardIdx > 9 ? true: false

    }



    // @action.bound showSignUp() {
    //     this.ui__showSignUp = true;
    // }

}

export default UIStore