import firebase from "firebase/app";
import { USER_EMAILS, FBDatabase } from "./firebase-client"


const FBSubmitEmail = (email: string) => {    
    return FBDatabase.collection(USER_EMAILS)
        .add({
            email,
            createdAt: firebase.firestore.Timestamp.now()
        })
        .catch(() => {
            console.error('error saving email', email)
        })
}

export default FBSubmitEmail