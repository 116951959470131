import firebase from "firebase/app";
import { generateDateStr } from "utils/general";
import { COLLECTION_ID, FBDatabase, FBStorage, STORAGE_REF } from "./firebase-client"

type ImageData = {
    file: File;
    qid: string;
    type?: string;
    createdAt?: firebase.firestore.Timestamp;
}

const FBSubmitImageInput = (data: ImageData) => {
    data.type = "Image";
    data.createdAt = firebase.firestore.Timestamp.now();

    const fileName = `${generateDateStr()}__BC__${data.file.name.replace(/[^a-zA-Z0-9]/g, "")}`;
    const fileRef = FBStorage.ref(STORAGE_REF).child(fileName);
    
    return fileRef.put(data.file)
        .then(snapshot => {
            return FBDatabase.collection(COLLECTION_ID)
                .add({
                    qid: data.qid,
                    type: data.type,
                    createdAt: data.createdAt,
                    file: fileRef.fullPath
                })
        })
        .catch(e => {
            console.error(e)
        })
}

export default FBSubmitImageInput