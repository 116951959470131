import { useStore } from "hooks/useStore"
import { observer } from "mobx-react-lite";

const MobileCardDecrement = observer(() => {
    const {uiStore: {decrementCardsMobile, isMobile}} = useStore();

    if (!isMobile) return null

    return (
        <div onClick={decrementCardsMobile}>

            <style jsx>{`
                div {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width:40px;
                    height: 100%;
                    z-index: 100;
                }
            `}</style>

        </div>
    )
})

export default MobileCardDecrement