import React from 'react'
import { CARDLAYOUT } from "../../../utils/layout/CardLayout"
import {observer} from 'mobx-react-lite'
import { useStore } from "hooks/useStore";
import { getCardTransformString } from 'utils/layout/getCardTransformString';


const CardOne = observer(() => { 
    const {uiStore: {tweens__cardOne, isMobile, incrementCardsMobile, mobile__cardOne__toggled}} = useStore();

    const transforms = CARDLAYOUT.cards.cardOne.tranforms;
    const initialPosition = CARDLAYOUT.cards.cardOne.initialPosition;

    const transformStyle = isMobile ? {} :
    {
        transform: getCardTransformString(initialPosition, transforms, tweens__cardOne)
    };

    return (
        <div onClick={isMobile ? incrementCardsMobile : undefined} style={transformStyle} className={mobile__cardOne__toggled ? "card toggled card--1" : "card card--1"}>
            <CardShape/>
            <CardContents/>
        </div>
    )
})

const CardShape = observer((props) => {
    const {uiStore: {isMobile, windowWidth}} = useStore();
    let {width, height, borderRadius} = CARDLAYOUT;

    if (isMobile) {
        width = windowWidth - 20;
        height = windowWidth / .7;
    } else {
        height = 650;
        width = 540;
    }

    return (
        <div className="card-shape">
            <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
                <defs>
                    <mask id="mask_1">
                        <rect width="100%" height="100%" fill="#fff" />
                        {/* {isMobile ? null  : <rect x="40" y="150" width="120" height="120" rx="5"/>} */}
                    </mask>
                </defs>
                <rect className="masked" width="100%" height="100%" rx={borderRadius} fill={"#3936B2"} />
                <style jsx>{`
                    .masked {
                        mask: url("#mask_1");
                    }
                `}</style>
            </svg>
        </div>
    )
});

const CardContents = React.memo((props) => {

    return (
        <div className="card__contents flex--column">
            <h2 className="text-l uppercase">
                Why Are Binary Calculations Inadequate?
            </h2>

            <p className="text-s">
                Binary Calculations Are Inadequate—a project, app and workshop series that asks how we can make the data-driven algorithms that increasingly control our daily lives more caring. By answering questions anonymously, you donate data to create more descriptive, comprehensive, and supportive datasets that represent our communities with complexity, love and deep-seated understanding. Your offerings will help create nuanced datasets that center and honor lives, cultures and values often under considered or ignored in the technosphere.
            </p>

            <style jsx>{`

                h2, p {
                    color: white;
                }
                
            `}</style>
        </div>
    )
})

export default CardOne