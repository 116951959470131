import React from 'react'

const Privacy = () => {
    return (
        <div>
            <img src="/assets/images/logo.gif" alt=""/>

            <h1>
                Privacy Policy 
            </h1>
            <p>
                <i>
                    Your privacy is vital to us. Your answers will be anonymized. Inappropriate content will be removed. On the upside, data donated will be used to create less biassed, full-spectrum datasets that inform algorithmic systems more deeply, broadly, and carefully. These data sets are made available with the intention to be used for communally supportive projects, but ultimately can be used by any project. We hope you’ll donate so we can craft our digital future together.
                </i>
            </p>
            <p>
                When Binary Calculations Are Inadequate to Assess Us (that’s us) receives information from you, our Privacy Policy describes how we handle that information.
            </p>

            <h3>
                Account data
            </h3>

            <p>
                You do not need to create an account to use Binary Calculations. You can delete the app at any time.
            </p>

            <h3>
                Newsletter
            </h3>
             
            <p>
                You can subscribe to our newsletter, in which case we receive your email address. You can unsubscribe at any time and your email will be removed from the newsletter distribution list. 
            </p>

            <h3>
                Voice Recordings
            </h3>
            <p>
                Voice recordings, along with any optionally provided demographic data, may be available in the Binary Calculations database for public consumption and use under a Creative Commons Zero License (CC0).
            </p>

            <h3>
                Text
            </h3>
            <p>
                If you submit written sentences, we may also make those available in the Binary Calculations database for public consumption under CC0. We will not associate these sentences with any demographic data you voluntarily submit.
            </p>

            <h3>
                Interaction data
            </h3>
            <p>
                We use machine learning to understand how you interact with the Binary Calculations app or website. For example, we may use cookies to track de-identified information such as interactions with buttons and menus, and session length.
            </p>

            <h3>
                Technical data
            </h3>
            <p>
                We use machine learning to understand better how you interact with the Binary Calculations app or website. For example, we may use cookies to track de-identified information such as the number of voice samples you record or listen to, interactions with buttons and menus, and session length. We also collect the URL and title of the Binary Calculations pages you visit. To consistently improve the Binary Calculations experience, we collect information about browser type and version, viewport size, and screen resolution. This allows us to understand how people interact with Binary Calculations so we can improve it. 

            </p>

            <h3>
                What do we mean by "personal information?"
            </h3>
            <p>
                Binary Calculations defines "personal information" as information that either directly identifies you (like your name, email address, or billing information) or can be reasonably linked or combined to identify you (like an account identification number or IP address). Any information that falls outside of this is "non-personal information."

            </p>
            <p>
                If we store your personal information with information that is non-personal, we consider the combination of personal information.  We intend to remove personal data from the data collected from you. This makes the remaining information non-personal.
            </p>
            
            <h3>
                How do we learn information about you?
            </h3>
            <p>
                We learn information about you when:
                You give it to us directly (for example, answer Binary Calculations questions);
                Someone else tells us information about you (for example, when a friend or community member uploads an image of you)
            </p>

            <h3>
                What do we do with your information once we have it?
            </h3>
            <p>
                When you give us information, we will use it in the ways for which you've given us permission. Generally, we will use the information you contribute to Binary Calculations to create open source datasets for use by the general public.
            </p>

            <h3>
                How do we store and protect your personal information?
            </h3>
            <p>
                We keep as little personal information as possible. We are committed to protecting your personal information once we have it.  Despite our efforts, if we learn of a security breach, we'll notify you so that you can take appropriate protective steps.
            </p>
            <p>
                We also don't want your personal information for any longer than we need it, so we only keep it long enough to do what we collected it for. Once we don't need it, we take steps to destroy it unless we are required by law to keep it longer.
            </p>

            <h3>
                What if we change this privacy policy or any of our privacy notices?
            </h3>
            <p>
                We may need to change this policy and our notices. Updates will be posted online. Your continued use of the Binary Calculations App constitutes your acceptance of such changes.
            </p>

            <h3>
                Acknowledgements
            </h3>
            <p>
                Our Privacy Policy is adapted from the Mozilla Foundation and Common Voice policies.
            </p>


            




            <style jsx>{`
                div {
                    padding: 20px 40px;
                }

                h3, h1 {
                    margin: 30px 0 12px 0;
                }
                p {
                    margin: 8px 0;
                }
            `}</style>
        </div>
    )
}

export default Privacy