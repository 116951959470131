import React from 'react'
import FBSubmitTagInput from 'utils/firebase/FBSubmitTagInput';
import { FormSubmitBtn } from './subcomponents';

type CardFormProps = {onSubmit: () => void;};
type CardFormState = {values: string[]; submitting: boolean; closed: boolean;};

const colorOptions = [
    'Black',
    'Brown',
    'Tan',
    'Yellow',
    'White',
    'Pink',
    'Umber',
    'Oche',
    'Obsidian',
    'Alabaster',
    'Chestnut',
    'Golden',
    'Porcelain',
    'Sand',
    'Warm Beige',
    'Beige',
    'Espresso',
    'Ivory',
    'Honey',
    'Marshmallow',
    'Pecan',
    'Amber',
    'Red',
    'Bronze',
    'Eggshell',
    'Olive',
    'Melanated',
    'Almond',
    'Caramel',
    'Mahogany',
    'Mustard',
    'Pecan',
    'Hickory'
];

const eventOptions = [
    'Wedding',
    'Graduation',
    'Divorce',
    'Party',
    'Dinner party',
    'Funeral',
    'Work meeting',
    'Celebration',
    'Work',
    'Theater',
    'Play',
    'Waiting',
    'Family reunion',
    'Festival',
    'Award Show',
    'Ball',
    'Sporting event',
    'Birthday party',
    'Marathon',
    'Bar/bat mitzvah',
    'Auction',
    'Reading',
    'Cabaret',
    'Congress',
    'Convention',
    'Dance',
    'Ball',
    'Disco',
    'Debate',
    'Exhibition',
    'Fashion Show',
    'Forum',
    'Hackathon',
    'Meet-up',
    'Improv show',
    'Open mic',
    'Performance art',
    'Prom',
    'Escape room',
    'Rally',
    'Rave',
    'Church',
    'Reunion',
    'Roast',
    'Roundtable',
    'Treasure hunt',
    'Film screening',
    'Comedy show',
    'Tasting',
    'Unconference',
    'Virtual tour',
    'Webinar',
    'Workshop'
]

const now = 2070;    
const years = Array(now - (now - 371)).fill('').map((v, idx) => (now - idx).toString());

class CardFormTags extends React.Component<CardFormProps, CardFormState> {
    constructor(props) {
        super(props);

        this.state = {
            values: [],
            submitting: false,
            closed: false
        };
    }

    _updateForm = (idx, val) => {
        const values = this.state.values;

        values[idx] = val;

        this.setState({
            values
        });
    }


    _submitForm = async () => {
        try {
            this._startSubmission();

            // submit and move to next card!!
            const resp = await FBSubmitTagInput(this.state.values);

            this._endSubmission();
            this._closeSubmissions();
            this.props.onSubmit();
            // move to net card
        } catch(e) {

            console.error(e)

            this._endSubmission();
            
        }

    }

    _startSubmission() {
        this.setState({
            submitting: true
        })
    }

    _endSubmission() {
        this.setState({
            submitting: false
        });
    }

    _closeSubmissions() {
        this.setState({
            closed: true
        });
    }

    render() {
        return (
            <div className="card-form__tags">
                <DropDownTag idx={0} updateForm={this._updateForm} position={{top: 60, left: 0}} options={colorOptions} label="Color?"/>
                <DropDownTag idx={1} updateForm={this._updateForm} position={{top: 180, left: 200}} options={eventOptions} label="Event?"/>
                <DropDownTag idx={2} updateForm={this._updateForm} position={{top: 300, left: 40}} options={years} label="Date?"/>
                
                <FormSubmitBtn onClick={this._submitForm} canSubmit={!!this.state.values[0] &&  !!this.state.values[1] && !!this.state.values[2] && !this.state.submitting && !this.state.closed} />
               
                <style jsx>{`
                    .card-form__tags {
                        position: relative;
                        height: 100%;
                    }   
                `}</style>

            </div>
        )
    }

}

type TagProps = {
    position: {top: number; left: number;};
    options: string[];
    idx: number;
    updateForm: (idx: number, val: string)  => void;
    label: string;
};

type TagState = {text: string;};


class DropDownTag extends React.Component<TagProps, TagState>  {

    constructor(props) {
        super(props);

        this.state = {
            text: props.label
        }
    }

    _handleChange = (e) => {
        const idx = e.target.value;
        if (!parseInt(idx) && parseInt(idx) !== 0) return

        const text = this.props.options[idx];
        if (!text) return

        this.setState({
            text
        });

        this.props.updateForm(this.props.idx, text);
    }

    render() {
        return (
            <div onClick={e => e.stopPropagation()} className="tag flex">
                <TextContainer text={this.state.text}/>
    
                <select onChange={this._handleChange}>
                    <option value={-1} disabled selected>{this.props.label}</option>

                    {this.props.options.map((option, idx) => {
                        return <option value={idx}>{option}</option>
                    })}
                </select>
    
                <div className="arrow flex--center">
                    <img src="/assets/images/dropdown-icon.svg" alt=""/>
                </div>
    
    
                <style jsx>{`
                    .tag {
                        padding: 10px 10px;
                        border-radius: 25px;
                        cursor: pointer;
                        background: white;
                        width: 220px;
                        text-align: center;
                        cursor: pointer;
    
                        position: absolute;
                        top: ${this.props.position.top}px;
                        left: ${this.props.position.left}px;
                    }

                    select {
                        position: absolute;
                        top: 5px;
                        left: 10px;
                        width: calc(100% - 20px);
    
                        opacity: 0;
                        cursor: pointer;
                    }

                    .tag:hover .arrow{
                        transform: translateY(-50%) translateY(2px);
                    }

                    .arrow {
                        text-align: center;
                        flex: 1;
                        position: absolute;
                        right: 12px;
                        top: 50%;
                        transform: translateY(-50%);
                        pointer-events: none;
                        transition: transform .2s ease;
                    }

                    @media (max-width: 769px) {
                        .tag {
                            left: 90px;
                            top: 20%;
                        }

                        .tag:nth-of-type(2) {
                            left: 10px;
                            top: 50%;
                        }
                        .tag:nth-of-type(3) {
                            top: 80%;
                        }    
                    }
                    



                `}</style>
            </div>
        )
    }

}

const TextContainer = ({text}: {text: string;}) => {

    return (
        <div>
            <h6 className="text-s text-regular">{text}</h6>

            <style jsx>{`
                div {
                    width: 90%;
                }
            `}</style>
        </div>
    )
}
export default CardFormTags