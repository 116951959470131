import React, { useState } from 'react'
import { CARDLAYOUT } from "../../../utils/layout/CardLayout"
import {observer} from 'mobx-react-lite'
import { useStore } from "hooks/useStore";
import { getCardTransformString } from 'utils/layout/getCardTransformString';

// var easeOutQuad = function (time: number, duration: number, valueStart: number, valueEnd: number) {
//     return -valueEnd *(time/=duration)*(time-2) + valueStart;
// }

const CardTwo = observer(() => {
    const {uiStore: {tweens__cardTwo, isMobile, incrementCardsMobile, mobile__cardTwo__toggled}} = useStore();
    const transforms = CARDLAYOUT.cards.cardTwo.tranforms;
    const initialPosition = CARDLAYOUT.cards.cardTwo.initialPosition;

    const transformStyle = isMobile ? {} :
    {
        transform: getCardTransformString(initialPosition, transforms, tweens__cardTwo)
    };
    
    return (
        <div onClick={isMobile ? incrementCardsMobile  : undefined} style={transformStyle} className={mobile__cardTwo__toggled ? "card toggled card--2" : "card card--2"}>

            <CardShape></CardShape>
            <CardContents></CardContents>

        </div>
    )
})


const CardShape = observer((props) => {
    const {uiStore: {isMobile, windowWidth}} = useStore();
    let {width, height, borderRadius, circleSizeSmall, padding} = CARDLAYOUT;

    
    if (isMobile) {
        width = windowWidth - 20;
        height = windowWidth / .7;
    }

    return (
        <svg  className="card-shape" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <defs>
                <mask id="mask_2">
                    <rect width="100%" height="100%" fill="#fff" />

                    <circle cx={circleSizeSmall + padding} cy={circleSizeSmall + padding} r={circleSizeSmall}/>
                    <circle cx={width - (circleSizeSmall + padding)} cy={circleSizeSmall + padding} r={circleSizeSmall}/>

                    {/* {isMobile ? null : 
                        <React.Fragment>
                            <rect x="210" y="170" width="70" height="140" rx="5"/>
                            <rect x="175" y="205" width="140" height="70" rx="5"/>
                            <rect x="192.5" y="187.5" width="105" height="105" rx="5"/>
                        </React.Fragment>
                    } */}

                </mask>
            </defs>
            
            <rect className="masked" width="100%" height="100%" rx={borderRadius} fill={"#F7D96D"} />

            <style jsx>{`
                .masked {
                    mask: url("#mask_2");
                }
            `}</style>
        </svg>
    )
});

const CardContents = React.memo((props) => {
    return (
        <div className="card__contents flex--column">
            <h2 className="text-l text-center uppercase">
                Should I contribute?
            </h2>

            <p className="text-s">
                ​​Every answer you provide contributes to a people’s data commons of ideas, content, and more precise labelling. We aim to create more holistic, broadly representative, inclusive datasets reflecting the depth and artifacts of the communities we care about. 
                The data commons—one for images and one for text—created through Binary Calculations, will model how supportive databases of care can be curated, maintained, and disseminated while centering the idea of building public trust and equity in the technosphere.
            </p>

            <style jsx>{`

                h2, p {
                    color: black;
                }
            `}</style>
        </div>
    )
})


export default CardTwo