import React, { useState } from 'react'
import { CARDLAYOUT } from "../../../utils/layout/CardLayout"
import {observer} from 'mobx-react-lite'
import { useStore } from "hooks/useStore";
import { getCardTransformString } from 'utils/layout/getCardTransformString';

const CardThree = observer(() => {
    const {uiStore: {tweens__cardThree, isMobile, incrementCardsMobile, mobile__cardThree__toggled}} = useStore();
    const transforms = CARDLAYOUT.cards.cardThree.tranforms;
    const initialPosition = CARDLAYOUT.cards.cardThree.initialPosition;

    const transformStyle = isMobile ? {} :
    {
        transform: getCardTransformString(initialPosition, transforms, tweens__cardThree)
    };

    
    return (
        <div onClick={isMobile ? incrementCardsMobile : undefined} style={transformStyle} className={mobile__cardThree__toggled ? "card toggled card--3" : "card card--3"}>

            <CardShape></CardShape>
            <CardContents></CardContents>

        </div>
    )
})


const CardShape = observer((props) => {
    const {uiStore: {isMobile, windowWidth}} = useStore();
    let {width, height, borderRadius, circleSizeSmall, padding} = CARDLAYOUT;

    if (isMobile) {
        width = windowWidth - 20;
        height = windowWidth / .7;
    }

    return (
        <svg  className="card-shape" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <defs>
                <mask id="mask_3">
                    <rect width="100%" height="100%" fill="#fff" />
                    <circle cx={circleSizeSmall + padding} cy={circleSizeSmall + padding} r={circleSizeSmall}/>
                    <circle cx={width - (circleSizeSmall + padding)} cy={circleSizeSmall + padding} r={circleSizeSmall}/>
                    {isMobile ? null : <circle cx="350" cy="160" r="70"/>}
                </mask>
            </defs>

            <rect className="masked" width="100%" height="100%" rx={borderRadius} fill={"#1EBF8B"} />


            <style jsx>{`
                .masked {
                    mask: url("#mask_3");
                }
            `}</style>
        </svg>
    )
});

const CardContents = React.memo((props) => {
    return (
        <div className="card__contents flex--column">
            <div></div>
            <div>
                <h2 className="text-l title">
                What about my privacy?
                </h2>

                <p className="text-s">
                    Our datasets will be open and available to anyone wanting to develop data-centric projects without relying exclusively on big data or tech. Read more of our <a href="/privacy">privacy policy</a>.
                </p>

            </div>

            <style jsx>{`

                a {
                    color: white;
                }
                h2 {
                    margin-bottom: 20px;
                }
                h2, p {
                    color: black;
                }

                @media (max-width: 769px) {
                    .title{
                        position:absolute;
                        top:4rem;
                    }
                }
            `}</style>
        </div>
    )
})


export default CardThree