import firebase from "firebase/app";
import { COLLECTION_ID, FBDatabase } from "./firebase-client"



const FBSubmitTagInput = (tags: string[]) => {
    return FBDatabase.collection(COLLECTION_ID)
        .add({
            qid: "TAG1",
            tags,
            type: "Tags",
            createdAt: firebase.firestore.Timestamp.now()
        })
        .catch(() => {
            console.error('error saving tag input')
        })
};



export default FBSubmitTagInput