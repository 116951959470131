import React, { Ref } from 'react'
import FBSubmitTextInput from 'utils/firebase/FBSubmitTextInput';
import FBSubmitAudioInput from 'utils/firebase/FBSubmitAudioInput';
import { FormSubmitBtn } from './subcomponents';
// import recordUserAudio from 'utils/audio/RecordUserAudio';

declare var MediaRecorder: any;

type CardProps = {onSubmit: () => void; qid: string;};
type CardState = { activeType: string; recordButtonHidden: boolean;};

class CardFormTextAudio extends React.Component<CardProps, CardState> {
    canRecord: boolean

    constructor(props) {
        super(props);

        this.state = {
            activeType: "text",
            recordButtonHidden: false
        }

        this.canRecord = !!navigator.mediaDevices;
    }

    _setRecordingState = () => {
        this.setState({
            activeType: "audio"
        });
    }

    _setTextState = () => {
        this.setState({
            activeType: "text"
        });
    }

    _hideAudioButton = () => {
        this.setState({
            recordButtonHidden: true
        })
    }

    _showAudioButton = () => {
        this.setState({
            recordButtonHidden: false
        })
    }

    render() {
        const {activeType} = this.state;

        return (
            <div className="card-form__text-audio"  onClick={e => e.stopPropagation()}>
                {
                    activeType === "audio"  ?
                    <ClearButton onClick={this._setTextState}/> :
                    <RecordButton canRecord={this.canRecord} hidden={this.state.recordButtonHidden} onClick={this._setRecordingState}/>
                }

                {
                    activeType === "audio" ? 
                    <AudioForm qid={this.props.qid} onSubmit={this.props.onSubmit} setTextState={this._setTextState}/> :
                    <TextForm qid={this.props.qid} onSubmit={this.props.onSubmit} hideAudioButton={this._hideAudioButton} showAudioButton={this._showAudioButton}/>
                }


                <style jsx>{`
                    .card-form__text-audio {
                        position: relative;
                        height: 100%;
                        margin-top: 20px;
                        max-height: 230px;
                        contain: strict;

                    }


                    .card-form__text-audio :global(textarea, .audio-form) {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        
                        resize: none;
                        background: white;
                        color: black;
                        border: none;
                        outline: none;
                        border-radius: 25px;
                        padding: 20px;
                        // transition: background .2s ease;
                    }

                    .card-form__text-audio:hover  :global(textarea, .audio-form) {
                        background: var(--color--whiteHover);
                    }
                `}</style>
            </div>
        )
    }
}

type TextFormProps = {onSubmit: () => void; hideAudioButton: () => void; showAudioButton: () => void; qid: string;};
type TextFormState = { hasText: boolean; submitting: boolean};

class TextForm extends React.PureComponent<TextFormProps, TextFormState> {
    textInput: React.RefObject<HTMLTextAreaElement>;
    constructor(props) {
        super(props);

        this.state = {
            hasText: false,
            submitting: false
        };

        this.textInput = React.createRef();
    };

    _updateText = () => {
        const textInput = this.textInput.current;
        if (!textInput) return;
        const text = textInput.value;

        if (!!text && !this.state.hasText) {
            this.setState({
                hasText: true
            }, this.props.hideAudioButton)
        } else if (!!!text && this.state.hasText) {
            this.setState({
                hasText: false
            }, this.props.showAudioButton)
        }
    }

    _submitForm = async () => {
        const textInput = this.textInput.current;
        if (!textInput) return;
        const text = textInput.value;
        if (!text) return

        try {
            this._startSubmission();

            await FBSubmitTextInput({text, qid: this.props.qid});
            // go to next question
            // reset...;
            this._resetForm();
            this._endSubmission();
            this.props.onSubmit();

        } catch(e) {
            // error handling
            console.error(e)
            this._endSubmission()
        }
    }

    _resetForm = () => {
        const textInput = this.textInput.current;
        if (!textInput) return;

        textInput.value = ""
        
        this.setState({
            hasText: false
        }, this.props.showAudioButton)
    }

    _startSubmission() {
        this.setState({
            submitting: true
        })
    }

    _endSubmission() {
        this.setState({
            submitting: false
        })
    }

    render() {
        return (
            <div>
                <textarea className="text-s" onChange={this._updateText} ref={this.textInput} cols={30} rows={2} maxLength={750} placeholder="Donate an answer"></textarea>
                <FormSubmitBtn onClick={this._submitForm} canSubmit={!!this.state.hasText && !this.state.submitting}/>
                <style jsx>{`
                    div {
                        contain: strict;
                        width: 100%;
                        height: 100%;
                    }


                `}</style>
            </div>
        )
    }
}
 
type AudioProps = {onSubmit: () => void; setTextState: () => void;  qid: string;};
type AudioState = {recording: boolean; audio: string; currentTime: number; submitting: boolean; isPlaying: boolean;}

class AudioForm extends React.Component<AudioProps, AudioState> {
    audioPlayer: React.RefObject<HTMLAudioElement>;
    isSafari: boolean;

    constructor(props) {
        super(props);

        this.state = {
            recording: false,
            isPlaying: false,
            audio: "",
            currentTime: 0,
            submitting: false
        };

        this.audioPlayer = React.createRef();
        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    }


    MAX_SECONDS = 60;
    stopped = false;
    recordedChunks: any[] = [];
    mediaRecorder: any = null;
    // audioOptions = {mimeType: 'audio/webm'};
    audioOptions = {};
    startTime = Date.now();   

    timerInterval: any = undefined;

    audioBlob: null | Blob = null;

    // downloadLink = document.getElementById('download');
    // stopButton = document.getElementById('stop');

    componentDidMount() {
        this.initRecording();
    }

    _stopRecording() {
        if (this.stopped) return 
        this.stopTimer();

        this.mediaRecorder.stop();
        this.stopped = true;

        this.setState({
            recording: false
        })
    }

    initRecording() {        
        return navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(this.recordUserAudio.bind(this))
    }

    startTimer() {
        this.startTime = Date.now();
        this.timerInterval = setInterval(this.tick.bind(this), 200)
    }

    tick() {
        const time = (Date.now() - this.startTime) / 1000;

        if (time > this.MAX_SECONDS) {
            this._stopRecording()
        }

        this.setState({
            currentTime: Math.floor(time)
        })
    }


    stopTimer() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }

    recordUserAudio(stream) {

        this.mediaRecorder = new MediaRecorder(stream, this.audioOptions);

        this.mediaRecorder.addEventListener('dataavailable', this._handleDataAvailable.bind(this));
        this.mediaRecorder.addEventListener('stop', this._finishRecording.bind(this));
        
        this.mediaRecorder.start();
        
        this.setState({
            recording: true
        }, this.startTimer.bind(this));
    }

    _playAudio = () => {
        if (!this.audioPlayer.current) return

        this.audioPlayer.current?.play();
    }

    _pauseAudio = () => {
        if (!this.audioPlayer.current) return

        this.audioPlayer.current?.pause();
    }

    _setIsPlaying = () => {
        this.setState({
            isPlaying: true
        })
    }

    _setStopPlaying = () => {
        this.setState({
            isPlaying: false
        })
    }

    _handleDataAvailable({data}: {data: any}) {
        if (data.size > 0) {
            this.recordedChunks.push(data);
        }
    }

    _finishRecording() {
        this.audioBlob = new Blob(this.recordedChunks);

        const url = URL.createObjectURL(this.audioBlob);
        this.setState({
            audio: url
        });
    }

    _submitForm = async () => {
        if (this.audioBlob === null) return
       
        this._startSubmission();

        try {
            const resp = await FBSubmitAudioInput({audio: this.audioBlob, qid: this.props.qid});
            
            this._endSubmission();
            this.props.setTextState();
            this.props.onSubmit();

        } catch(e) {
            this._endSubmission();

            console.error(e);
        }
    }

    _startSubmission() {
        this.setState({
            submitting: true
        })
    }

    _endSubmission() {
        this.setState({
            submitting: false
        })
    }

    _renderRecordingElements() {
        return (
            <React.Fragment>
                <img className="recording-icon" src="/assets/images/recording-icon.svg" alt=""/>

                <div className="stop-btn" onClick={this._stopRecording.bind(this)}>Stop Recording</div> 
                <h6 className="time text-s">{this.MAX_SECONDS - this.state.currentTime}s</h6>

                <style jsx>{`
                    img {
                        // width: 130px;
                    }
                    .time {
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        font-size: 14px;
                        color: var(--color--lightgray);
                    }
    
                    .stop-btn {
                        position: absolute;
                        bottom: 15px;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 5px 20px;
                        background: var(--color--lightgray);
                        color: white;
                        cursor: pointer;
                        border-radius: 25px;
                        cursor: pointer;
                    }
                `}</style>
            </React.Fragment>
        )
    }

    _renderPlaybackElements() {
        if (!this.state.audio) return null

        let playPause: any = null;
        if (!this.isSafari) {
            playPause = this.state.isPlaying ?
            <img onClick={this._pauseAudio} className="play-btn" src="/assets/images/pause-btn.svg" alt=""/>: 
            <img onClick={this._playAudio} className="play-btn" src="/assets/images/play-btn.svg" alt=""/>

        }
        return (
            <React.Fragment>
                {playPause}

                <audio ref={this.audioPlayer} onPlay={this._setIsPlaying} onEnded={this._setStopPlaying} onPause={this._setStopPlaying} className="audio" src={this.state.audio} controls></audio>
               
                <FormSubmitBtn onClick={this._submitForm} canSubmit={!!this.state.audio && !this.state.submitting}/>
                <style jsx>{`
                    img {
                        cursor: pointer;
                    }

                    .audio {
                        display: none;
                    }
                `}</style>
           </React.Fragment>
        )
    }

    render() {
        
        return (
            <div className="audio-form flex--center">

            {
                this.state.recording ?
                this._renderRecordingElements():
                this._renderPlaybackElements()
            }




            <style jsx>{`
                .audio-form {
                    // background: #EEEEEE;
                }



            `}</style>
        </div>
        )
    }
}

const RecordButton = ({onClick, hidden, canRecord} : {onClick: () => void; hidden: boolean; canRecord: boolean;}) => {
    if (hidden || !canRecord) return null

    return (
        <React.Fragment>
            <svg onClick={onClick} className="record-btn" width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.740845" y="0.893372" width="48.6148" height="47.6799" rx="23.8399" fill="#EEEEEE"/>
                <path d="M25.0482 26.1356C26.6001 26.1356 27.8435 24.8828 27.8435 23.3309L27.8529 17.7215C27.8529 16.1695 26.6001 14.9168 25.0482 14.9168C23.4963 14.9168 22.2435 16.1695 22.2435 17.7215V23.3309C22.2435 24.8828 23.4963 26.1356 25.0482 26.1356ZM30.0032 23.3309C30.0032 26.1356 27.6285 28.0989 25.0482 28.0989C22.4679 28.0989 20.0932 26.1356 20.0932 23.3309H18.5039C18.5039 26.5189 21.0468 29.1553 24.1133 29.6134V32.6799H25.9831V29.6134C29.0496 29.1646 31.5925 26.5282 31.5925 23.3309H30.0032Z" fill="black"/>
            </svg>

            {/* <img onClick={onClick} className="record-btn" src="/assets/images/audio-form-icon.svg" alt=""/> */}

            <style jsx>{`
                .record-btn {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    color: white;
                    width: 50px;
                    z-index: 10;
                    border-radius: 4px;
                    cursor: pointer;
                }
            `}</style>
        </React.Fragment>
    )


}

const ClearButton = ({onClick}: {onClick: () => void;}) => {
    return (
        <React.Fragment>
            <img onClick={onClick} src="/assets/images/x-btn.svg" alt=""/>
            

            <style jsx>{`
                img {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    z-index: 10;
                    cursor: pointer;
                }
            `}</style>
        </React.Fragment>
    )

} 


export default CardFormTextAudio