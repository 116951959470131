import { useStore } from 'hooks/useStore'
import {observer} from 'mobx-react-lite'
import { useEffect } from 'react';

const ScrollPage = observer(() => {
    const {uiStore: {windowHeight, initScrollListener, isMobile}} = useStore();
    // return null
    if (isMobile) return null
    useEffect(initScrollListener);
    
    return (
        <div>

            <style jsx>{`
                div {
                    height: ${(windowHeight - 100) * 10}px;
                }
            `}</style>
        </div>
    )
}) 

export default ScrollPage