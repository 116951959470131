import React from 'react'
import FBSubmitEmail from 'utils/firebase/FBSubmitEmail'
import { validateEmail } from 'utils/general';

type EmailProps = {};
type EmailState = {hasText: boolean; showSignUpBtn: boolean; isComplete: boolean;}

class EmailForm extends React.PureComponent<EmailProps, EmailState> {
    textInput: React.RefObject<HTMLInputElement>;
    completionMessage: string;

    constructor(props) {
        super(props);

        this.state = {
            hasText: false,
            showSignUpBtn: true,
            isComplete: false
        };

        this.completionMessage = "Thank you!";
        this.textInput = React.createRef();
    }

    _updateText = () => {
        const textInput = this.textInput.current;
        if (!textInput) return;

        const text = textInput.value;
        const isValidEmail = validateEmail(text);

        if (!!text && !this.state.hasText && isValidEmail) {
            this.setState({
                hasText: true
            })
        } else if ((!!!text && this.state.hasText) || !isValidEmail) {
            this.setState({
                hasText: false
            })
        }
    }

    _removeSignUpBtn = () => {
        this.setState({
            showSignUpBtn: false
        }, () => {
            this.textInput.current?.focus()
        })
    }

    _showCompletion() {
        this.setState({
            isComplete: true
        })
    }

    _submitForm = async () => {
        try {
            const email = this.textInput.current;
            const val = email?.value;
            if (!val) return

            await FBSubmitEmail(val);
            this._showCompletion();
        } catch(e) {
            console.error(e)
        }
    }
    
    
    render() {
        return (
            <div onClick={e => e.stopPropagation()}>


                <div className="input-form flex">
                    {                
                        this.state.showSignUpBtn ? 
                        <div onClick={this._removeSignUpBtn} className="sign-up-btn flex--center-y">
                            <img src="/assets/images/email-icon.svg" alt=""/>
                            <h6 className="text-regular text-xs uppercase">
                                Sign up to help craft our digital future.
                            </h6>
                        </div> : 
                        null
                    }
                    <input className="text-xs" value={this.state.isComplete ? this.completionMessage : undefined} onChange={this._updateText} ref={this.textInput} type="text" placeholder="Example@email.com"/>
                    {this.state.isComplete ? null : <img className={this.state.hasText ? "subscribe-btn active" : "subscribe-btn"} onClick={this._submitForm} src="/assets/images/subscribe-btn.svg" alt=""/>  }              

                </div>

                <style jsx>{`

                    .input-form {
                        position: relative;
                        border-radius: 6px;
                        height: 56px;
                        width: 246px;
                        background: white;
                        padding-right: 12px;
                        margin: 0 auto;
                    }

                    input {
                        border: none;
                        outline: none;
                        height: 100%;
                        width: 100%;
                        text-align: center;
                        padding: 0 12px;
                        background: transparent;
                    }
                    
                    .subscribe-btn:hover {
                        transform: translateX(2px);
                    }

                    .subscribe-btn {
                        width: 24px;
                        cursor: pointer;
                        transition: all .2s ease;
                        opacity: .4;
                        pointer-events: none;
                    }

                    .subscribe-btn.active {
                        opacity: 1;
                        pointer-events: all;
                    }

                    .sign-up-btn {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: black;
                        color: white;
                        border-radius: 6px;
                        padding: 0 12px;
                        cursor: pointer;
                        text-align: left;
                        opacity: 1;
                        z-index: 100;
                        transition: background .2s ease;
                    }

                    .sign-up-btn:hover {
                        background: var(--color--offBlackHover);
                    }

                    h6 {
                        line-height: 1.2;
                    }

                    .sign-up-btn img {
                        margin-right: 12px;
                    }
                `}</style>
            </div>
        )
    }
}




export default EmailForm