import DataStore from "./DataStore";
import UIStore from "./UIStore";

class RootStore {
    uiStore: UIStore;
    dataStore: DataStore;

    constructor() {
        this.uiStore = new UIStore(this);
        this.dataStore = new DataStore(this);
    }
}

export default RootStore;