import React, { useState } from 'react'
import { CARDLAYOUT } from "../../../utils/layout/CardLayout"
import {observer} from 'mobx-react-lite'
import { useStore } from "hooks/useStore";
import { getCardTransformString } from 'utils/layout/getCardTransformString';

const CardNine = observer(() => {
    const {uiStore: {tweens__cardNine, isMobile, incrementCardsMobile, mobile__cardNine__toggled}} = useStore();
    const transforms = CARDLAYOUT.cards.cardNine.tranforms;
    const initialPosition = CARDLAYOUT.cards.cardNine.initialPosition;

    const transformStyle = isMobile ? {} :
    {
        transform: getCardTransformString(initialPosition, transforms, tweens__cardNine)
    };

    
    return (
        <div onClick={isMobile ? incrementCardsMobile : undefined} style={transformStyle} className={mobile__cardNine__toggled ? "card toggled card--9" : "card card--9"}>
            <CardShape></CardShape>
            <CardContents></CardContents>
        </div>
    )
})


const CardShape = React.memo((props) => {
    const {uiStore: {isMobile, windowWidth}} = useStore();
    let {width, height, borderRadius, circleSizeSmall, padding} = CARDLAYOUT;

    if (isMobile) {
        width = windowWidth - 20;
        height = windowWidth / .7;
    } else {
        height = 650;
        // width = 540;
    }

    return (
        <svg  className="card-shape" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <defs>
                <mask id="mask_9">
                    <rect width="100%" height="100%" fill="#fff" />

                    <circle cx={circleSizeSmall + padding} cy={circleSizeSmall + padding} r={circleSizeSmall}/>
                    <circle cx={width - (circleSizeSmall + padding)} cy={circleSizeSmall + padding} r={circleSizeSmall}/>
                  
                    <circle cx={circleSizeSmall + padding} cy={height - (circleSizeSmall + padding)} r={circleSizeSmall}/>
                    <circle cx={width - (circleSizeSmall + padding)} cy={height - (circleSizeSmall + padding)} r={circleSizeSmall}/>

                </mask>
            </defs>

            <rect className="masked" width="100%" height="100%" rx={borderRadius} fill={"#1EBF8B"} />


            <style jsx>{`
                .masked {
                    mask: url("#mask_9");
                }
            `}</style>
        </svg>
    )
});

const CardContents = React.memo((props) => {
    return (
        <div className="card__contents">
            <h6 className="text-s uppercase credits">Credits</h6>
            

            <h6 className="text-s text-regular">Brought to you by:</h6>
            <h6 className="text-s text-bold"><a href="https://www.stephaniedinkins.com/" target="_blank">Stephanie Dinkins Studio</a></h6>
           
            <h6 className="text-s text-regular">With support from:</h6>
            <h6 className="text-s text-bold">Mozilla Foundation, Knight<br></br> Foundation, Visions 2030</h6>

            <h6 className="text-s text-regular">Advisor & Collaborator:</h6>
            <h6 className="text-s text-bold"><a href="https://www.suryamattu.com/" target="_blank">Surya Mattu</a></h6>

            <h6 className="text-s text-regular">Collaborator:</h6>
            <h6 className="text-s text-bold"><a href="https://netabomani.com/" target="_blank">Neta Bomani</a></h6>

            <h6 className="text-s text-regular">Designer:</h6>
            <h6 className="text-s text-bold"><a href="https://www.psanches.com/" target="_blank">Pedro Sanches</a></h6>

            <h6 className="text-s text-regular">Design, Website & App Development:</h6>
            <h6 className="text-s text-bold"><a href="https://schoooool.com/" target="_blank">Andrew Herzog & Nicky Tesla & Ryan Sheehan</a></h6>

            <h6 className="text-s text-regular">Backend Development:</h6>
            <h6 className="text-s text-bold"><a href="https://playdo.io/" target="_blank">Sukanya Aneja & Brent Bailey</a></h6>


            <style jsx>{`
                .card__contents {
                    padding-top: 18px;
                    text-align: center;
                }

                .credits {
                    margin-bottom: 30px;
                }
                h6:nth-of-type(even) {
                    margin-top: 12px;
                }

                a:hover{
                    color:white;
                }

                h2, p {
                    color: black;
                }

                @media (max-width: 769px) {
                    .credits {
                        margin-bottom: 30px;
                    }
                }

                @media (max-width: 374px) {
                    
                    h6.text-s {
                        font-size: 14px;
                    }
                }
            `}</style>
        </div>
    )
})


export default CardNine