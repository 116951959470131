import React, { useState } from 'react'
import { CARDLAYOUT } from "../../../utils/layout/CardLayout"
import {observer} from 'mobx-react-lite'
import { useStore } from "hooks/useStore";
import { getCardTransformString } from 'utils/layout/getCardTransformString';

const CardTen = observer(() => {
    const {uiStore: {tweens__cardTen, isMobile, incrementCardsMobile, mobile__cardTen__toggled}} = useStore();

    const transforms = CARDLAYOUT.cards.cardTen.tranforms;
    const initialPosition = CARDLAYOUT.cards.cardTen.initialPosition;

    const transformStyle = isMobile ? {} :
    {
        transform: getCardTransformString(initialPosition, transforms, tweens__cardTen)
    };

    
    return (
        <div onClick={isMobile ? incrementCardsMobile : undefined} style={transformStyle} className={mobile__cardTen__toggled ? "card toggled card--10" : "card card--10"}>
            <CardShape></CardShape>
            <CardContents></CardContents>
        </div>
    )
})


const CardShape = observer((props) => {
    const {uiStore: {isMobile, windowWidth}} = useStore();
    let {borderRadius, cards: {cardTen: {height, width, mobileHeight}}} = CARDLAYOUT;

    if (isMobile) {
        width = windowWidth - 20;
        height = mobileHeight;
    }

    return (
        <svg  className="card-shape" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <rect className="masked" width="100%" height="100%" rx={borderRadius} fill={"#96A7FF"} />
        </svg>
    )
});

const CardContents = React.memo((props) => {
    return (
        <div className="card__contents flex--column">
            <h2 className="text-xl">
                Thank you.
            </h2>

            <p className="text-s">
                Your contribution is helping craft a better digital future.
            </p>

            <style jsx>{`
                .card__contents {
                    text-align: center;
                    justify-content:center;
                }
                h2, p {
                    color: black;
                    margin-bottom:1rem;
                }
            `}</style>
        </div>
    )
})


export default CardTen