import React from 'react'
import FBSubmitImageInput from 'utils/firebase/FBSubmitImageInput';
import { FormSubmitBtn } from './subcomponents';

type CardProps = {onSubmit: () => void; qid: string;};
type CardState = {file: File | null; submitting: boolean;};


class CardFormImage extends React.Component<CardProps, CardState> {

    constructor(props) {
        super(props);

        this.state = {
            file: null,
            submitting: false
        }
    }
    _submitForm = async () => {
        if (!this.state.file) {
            return
        }

        try {
            this._startSubmission();
            const resp = await FBSubmitImageInput({file: this.state.file, qid: this.props.qid});
            // reset? 
            // scroll to next card??....
            this._resetForm();
            this._endSubmission();
            this.props.onSubmit();

        } catch(e) {

            console.error(e);
            this._endSubmission();

        }
    }


    _handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (!files) return;

        const file = files[0];
        var filesize = parseFloat(((files[0].size/1024)/1024).toFixed(4)); // MB

        if (filesize > 10) return

        if (
            file.type !== "image/jpeg" &&
            file.type !== "image/png" &&
            file.type !== "image/webp"
        ) {

            console.log("error handling");

        } else {

            this.setState({
                file
            });

        }
    }

    _handleChange = (e) => {
        if (!e.target.files) return
        const file = e.target.files[0];

        this.setState({
            file
        })
        // console.log("have file: ", file)
        //handle file
    }

    _startSubmission() {
        this.setState({
            submitting: true
        })
    }

    _endSubmission() {
        this.setState({
            submitting: false
        });
    }

    _resetForm() {
        this.setState({
            file: null
        })
    }


    _renderPreviewSubmit() {
        return (
            
            <React.Fragment>
                <img onClick={this._resetForm.bind(this)} src="/assets/images/x-btn.svg" alt=""/>

                <ImagePreview file={this.state.file}/>
                <FormSubmitBtn onClick={this._submitForm} canSubmit={!!this.state.file && !this.state.submitting}/>
                <style jsx>{`
                    img {
                        position: absolute;
                        z-index: 10;
                        top: 15px;
                        right: 15px;
                        cursor: pointer;
                    }
                `}</style>
            </React.Fragment>
        )
    }

    _renderUploadForm() {
        return (
            <React.Fragment>
                <ImageDropArea onDrop={this._handleDrop}/>
                <input onChange={this._handleChange} accept="image/jpeg,image/png,image/webp" id="card-form__image-input" type="file"/>
            
                <div className="msg text-s">  
                    <div>
                        <img src="/assets/images/image-form-icon.svg" alt=""/>          
                    </div>   
                    <span>
                        Drag and drop  <br/> an image to donate
                    </span>
                </div>

                <style jsx>{`
                    img {
                        width: 50px;
                    }

                    .msg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 85%;
                        text-align: center;
                        color: var(--color--gray);
                        pointer-events: none;
                    }

                    .msg > span {
                        width: 75%;
                    }
                    input {
                        display: none;
                    }
                `}</style>

            </React.Fragment>
        )
    }
    render() {

        return (
            <div onClick={e => e.stopPropagation()}  className={this.state.file ? "card-form__image with-file" : "card-form__image"}>

                {
                    this.state.file ?
                    this._renderPreviewSubmit() : 
                    this._renderUploadForm() 
                }

                <style jsx>{`


                    .card-form__image {
                        position: relative;
                        height: 100%;
                        max-height: 230px;
                        background: white;
                        border-radius: 25px;
                        padding: 20px;
                        margin-top: 20px;
                    }


                    .card-form__image:hover {
                        background: var(--color--whiteHover);
                    }

                    .card-form__image:hover :global(label) {
                        background: var(--color--whiteHover);
                    }

                    .card-form__image.with-file {
                        max-height: none;
                        background: white;
                    }



                `}</style>
            </div>
        )
    }
}

const ImageDropArea = ({onDrop}: {onDrop: (e: any) => void;}) => {
    return (
        <label htmlFor="card-form__image-input" onDrop={onDrop} onDragOver={(e: any) => e.preventDefault()}>

            {/* <div > */}

                <style jsx>{`
                    label {
                        display: block;
                        border-radius: 20px;
                        cursor: pointer;
                        background: white;
                        color: black;
                        height: 100%;
                        border: 2px dashed var(--color--lightgray);
                    }
                    label:hover {
                        background: var(--color--whiteHover);
                    }
                `}</style>
            {/* </div> */}
        </label>
    )
}

const ImagePreview = ({file}: {file: File | null;}) => {
    if (!file) return null
    // var reader = new FileReader();
    const str = window.URL.createObjectURL(file);

    return (
        <div>
            <img src={str} alt=""/>
            
            <style jsx>{`
                div {
                    height: 100%;
                    border-radius: 25px;
                    position: relative;
                    padding: 20px;
                    background: white;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: calc(100% - 40px);
                    height: calc(100% - 40px);
                    object-fit: contain;
                }
            `}</style>
        </div>
    )
}


export default CardFormImage