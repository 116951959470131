import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { SignUpForm } from '.'

const Hero = observer(() => {
    const {uiStore: {windowHeight}} = useStore();
    // if (isMobile) return null

    return (
        <div className="hero flex--center">
            <div className="hero__inner">
                <Masthead/>
                <Description/>



            </div>


            <style jsx>{`
                .hero {
                    position: sticky;
                    top: 0;
                    width: 100%;

                    background: var(--color--bg);
                    padding: 200px 0;
                    padding-top: ${windowHeight / 4}px;
                }

                .hero__inner {
                    width: 880px;
                }

                @media(max-width: 769px) {
                    .hero {
                        position: static;
                        padding: 20px;
                        padding-top: 12px;
                        padding-bottom: 40px;

                    }

                    .hero__inner {
                        width: 100%;
                    }
                }
            `}</style>
        </div>
    )
})



const Description = () => {


    return (
        <div>
            <h1 className="text-m text-regular">
                Binary Calculations are Inadequate is an art project that asks how we can make the technological systems that control our relationships, governments, and institutions more caring.
            </h1>

            <SubDescription/>



            <style jsx>{`
                h1{
                    margin-top:1rem;
                }

                @media (max-width: 769px) {
                    h1{
                        font-size:1.6rem;
                        margin-top:3rem;

                    }
                    div {
                        padding-left: 20px;
                    }
                }
            `}</style>
        </div>
    )
}

const SubDescription = observer(() => {
    const {uiStore: {ui__showSubDescription, isMobile}} = useStore();

    return (
        <div className={ui__showSubDescription ? "grid" : "grid hide"}>

            <div>
                <p className="text-s">
                The algorithmic ecologies surrounding us are increasingly complex, extractive, and embedded into every aspect of our lives. We empower companies, big tech, and governments to track us with the trail of information we leave behind with each encounter. Some of these systems are helpful, some of them are harmful, most are built atop data that incompletely describe most inhabitants on the planet. For example, common datasets used for AI assisted medical diagnoses often under-represent people with darker skin.
                Data extracted and often augmented with biased historical information is used to assess, control, serve, appease and even titillate. Our needs, hopes, dreams, and desires, along with multitudes of cultural nuance, get averaged to uphold and serve the demands of the status quo. Binary Calculations aims to push algorithmic networks to become less reductive and encourage complexity, plurality, kindness, and generosity instead.
                </p>


                {!isMobile &&
                <div className="sign-up">
                    <SignUpForm/>
                    <ContactLink/>

                </div>
                }

            </div>



            <div>
                <div className="push-down">

                    <p className="rotate text-s">
                        Because binary calculations are inadequate to assess us, we are working to collaboratively design more nurturing algorithmic ecosystems centered on care and model their use.
                    </p>
                    {isMobile &&
                    <div className="sign-up">
                        <SignUpForm/>
                        <ContactLink/>
                    </div>
                }

                </div>

            </div>


            <style jsx>{`

            .sign-up{
                float:left;
                margin-top:1rem;
            }

                p {
                    margin: 30px 0;
                }

                .grid {
                    display: grid;
                    grid-template-columns: 560px 1fr;
                    grid-gap: 60px;
                    transition: opacity .6s ease-in;
                    opacity: 1;
                }

                .hide {
                    opacity: 0;
                }

                .rotate {
                    transform: rotate(6deg);
                    position:relative;
                    top:-1.5rem;
                    // font-size: 18px;
                }

                p.rotate {
                    margin-bottom: 60px;
                }
                .push-down {
                    transform: translateY(45px);
                }

                @media(max-width: 769px) {
                    p {
                        margin: 12px 0;
                        font-size:1.2rem;
                    }
                    .grid {
                        grid-template-columns: 1fr;
                        opacity: 1 !important;
                        gap: 0px;
                    }
                    .sign-up{
                        margin-top:0rem;
                        position:relative;
                        z-index:0 ;
                    }

                    .rotate {
                        transform: none;
                        width:100%;
                        margin-top: 2rem !important; // Pedro Warning
                        margin-bottom:1rem !important; // Pedro Warning
                    }
                    .push-down {
                        transform: none;
                    }
                }
            `}</style>
        </div>
    )
})

const Masthead = observer(() => {
    const {uiStore: {isMobile}} = useStore();

    return (
        <div className="masthead flex--space-between">
            <img src="/assets/images/logo.gif" alt=""/>
            {isMobile ? <ScrollToEndButton/> : null}
            <style jsx>{`
                img {
                    max-width: 100%;
                }

                @media (max-width: 769px) {
                    img {
                        width: 160px;
                    }
                }
            `}</style>
        </div>
    )
})

const ContactLink = () => {
    return (
        <div>
            <h6>Contact Us</h6>
            <a className="text-xs" href="mailto: hello@dinkins.studio">hello@dinkins.studio</a>

            <style jsx>{`
                div {
                    margin-top: 40px;
                }
                h6 {
                    margin-bottom: 2px;
                }
            `}</style>
        </div>
    )
}
const ScrollToEndButton = () => {
    const {uiStore: {scrollToEndMobile}} = useStore()
    return (
        <div onClick={scrollToEndMobile}>

            <style jsx>{`
                background: black;
                height: 22px;
                width: 22px;
                border-radius: 50%;
            `}</style>
        </div>
    )
}


export default Hero
