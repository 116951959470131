export const CARDLAYOUT = {
    height: 600,
    width: 500,
    borderRadius: 25,
    circleSizeSmall: 12,
    padding: 18,
    verticalDistanceBase: 60,
    verticalDistanceStack: 6,
    verticalDistanceLeft: 40,
    verticalDistance: 60,

    cards: {
        cardOne: {
            initialPosition: {
                "rotate": random(-2,2),
                "translate3d": {x: random(-100,-200), y: -10}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: -770,y: -300}},
                {type: "rotate", from: 0, to: 8}
            ],
        },
        cardTwo: {
            initialPosition: {
                "rotate":random(-2,2),
                "translate3d": {x:random(-100,-200), y: 0}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: 1100,y: 500}},
                {type: "rotate", from: 0, to: -10}
            ]
        },
        cardThree: {
            initialPosition: {
                "rotate": random(-2,2),
                "translate3d": {x: -150, y: -40}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: 1100,y: -300}},
                {type: "rotate", from: 0, to: 10}
            ]
        },
        cardFour: {
            initialPosition: {
                "rotate": 1.2,
                "translate3d": {x: -120, y: 30}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: -770,y: 440}},
                {type: "rotate", from: 0, to: -6}
            ]
        },
        cardFive: {
            initialPosition: {
                "rotate": 1.2,
                "translate3d": {x: -120, y: 30}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: -150,y: -700}},
                {type: "rotate", from: 0, to: 14}
            ]
        },
        cardSix: {
            initialPosition: {
                "rotate": random(-2,2),
                "translate3d": {x: -85, y: -10}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: 100,y: 650}},
                {type: "rotate", from: 0, to: 4}
            ]
        },
        cardSeven: {
            initialPosition: {
                "rotate": random(-2,2),
                "translate3d": {x: -45, y: 50}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: 400,y: -750}},
                {type: "rotate", from: 0, to: -30}
            ]
        },
        cardEight: {
            height: 400,
            width: 540,
            mobileHeight: 400,

            initialPosition: {
                "rotate": random(-2,2),
                "translate3d": {x: 30, y: 0}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: 900,y: -20}},
                {type: "rotate", from: 0, to: 11}
            ]
        },
        cardNine: {
            initialPosition: {
                "rotate": random(-2,2),
                "translate3d": {x: 40, y: 44}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: -1000,y: 30}},
                {type: "rotate", from: 0, to: -20}
            ]
        },
        cardTen: {
            height: 300,
            width: 375,
            mobileHeight: 220,

            initialPosition: {
                "rotate": random(-2,2),
                "translate3d": {x: 0, y: 0}
            },
            tranforms: [
                {type: "translate3d", from: {x: 0,y: 0}, to: {x: 700,y: -500}},
                {type: "rotate", from: 0, to: 32}
            ]
        }
    }
}


function map_range(value, low1, high1, low2, high2) {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}

function random(min, max) { 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }