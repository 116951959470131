import {action, observable, makeObservable} from 'mobx'

// import { client } from '../utils/sanity/sanity-client';
import RootStore from "./RootStore";


const SiteQuestions = {
    "TextAudio": [
        {text: "What would you need to make your life exactly as you imagine it to be?", id: "TA1"},
        {text: "How would you like to be seen or recognized?", id: "TA2"},
        {text: "What does care mean to you? ", id: "TA3"},
        {text: "What does it mean to thrive? Describe a time in your life when you were thriving.", id: "TA4"},
        {text: "What is the most meaningful action you could take at this moment?", id: "TA5"},
        // {text: "What would be the best way to make sure all of our neighbors are fed?", id: "TA6"},
        // {text: "What do you need to release to move forward?", id: "TA7"}
    ],
    "Image": [
        {text: "Upload an image of a cultural artifact you'd like to be remembered.", id: "IMG1"},
        {text: "Upload an image that illustrates close attention, concern, or responsibility in any way you see fit. Describe it in detail.", id: "IMG2"},
        {text: "Upload an image that illustrates abundance, flourishing, and generosity in any way you see fit. Describe it in detail.", id: "IMG3"},
        {text: "Upload an image or drawing of a thriving species.", id: "IMG4"}
    ],
    "Tag": [
        {text: "How would you describe that image?", id: "TAG1"}
    ]
};

class DataStore {
    rootStore: RootStore;
    imageQuestion: {text: string; id: string;};
    textAudioQuestion: {text: string; id: string;};

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.imageQuestion = SiteQuestions.Image[Math.floor(Math.random() * SiteQuestions.Image.length)];
        this.textAudioQuestion = SiteQuestions.TextAudio[Math.floor(Math.random() * SiteQuestions.TextAudio.length)];
    }

    data = {};
    



    // async fetchSiteData() {
    //     const query = `
    //         *[_type == "splashPage"][0]
    //     `;

    //     const params = {};

    //     try {
    //         const data = await client.fetch(query, params);
    //         this.setSiteData(data);
    //     } catch(e) {
    //         console.log(e)
    //     }
    // }

    // @action.bound setSiteData(data: {}) {
    //     this.data = data;
    //     this.dataReady = true;
    // }
}

export default DataStore