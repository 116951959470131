import firebase from "firebase/app";
import { generateDateStr } from "utils/general";
import { COLLECTION_ID, FBDatabase, FBStorage, STORAGE_REF } from "./firebase-client"

type AudioData = {
    audio: Blob;
    qid: string;
}

const FBSubmitAudioInput = ({audio, qid}: AudioData) => { 
    const fileName = `${generateDateStr()}__BC__user-audio.webm`;
    const fileRef = FBStorage.ref(STORAGE_REF).child(fileName);
    
    return fileRef.put(audio)
        .then(snapshot => {
            return FBDatabase.collection(COLLECTION_ID)
                .add({
                    qid: qid,
                    type: "Audio",
                    createdAt: firebase.firestore.Timestamp.now(),
                    file: fileRef.fullPath
                })
        })
        .catch(e => {
            console.error(e)
        })
}

export default FBSubmitAudioInput