import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/storage';



var firebaseConfig = {
    apiKey: "AIzaSyB1yTyug9_ZRofVuZ1wF5NnYdQhVWRCGA4",
    authDomain: "binarycalculations.firebaseapp.com",
    projectId: "binarycalculations",
    storageBucket: "binarycalculations.appspot.com",
    messagingSenderId: "856912895280",
    appId: "1:856912895280:web:402e592cb2147b731fbcf5"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


export const FBDatabase = firebase.firestore();
export const FBStorage = firebase.storage();
export const COLLECTION_ID = 'teaser-site'
export const USER_EMAILS = 'sign-up-emails';
export const STORAGE_REF = 'teaser-site'