import React, { useState } from 'react'
import { CARDLAYOUT } from "../../../utils/layout/CardLayout"
import {observer} from 'mobx-react-lite'
import { useStore } from "hooks/useStore";
import { CardFormTags } from 'components/card_form';
import { getCardTransformString } from 'utils/layout/getCardTransformString';

const CardFive = observer(() => {
    const {uiStore: {tweens__cardFive, isMobile, incrementCardsMobile, mobile__cardFive__toggled}} = useStore();
    const transforms = CARDLAYOUT.cards.cardFive.tranforms;
    const initialPosition = CARDLAYOUT.cards.cardFive.initialPosition;

    const transformStyle = isMobile ? {} :
    {
        transform: getCardTransformString(initialPosition, transforms, tweens__cardFive)
    };

    return (
        <div onClick={isMobile ? incrementCardsMobile : undefined} style={transformStyle} className={mobile__cardFive__toggled ? "card toggled card--5" : "card card--5"}>
            <CardShape/>
            <CardContents/>
        </div>
    )
})


const CardShape = React.memo((props) => {
    const {uiStore: {isMobile, windowWidth}} = useStore();
    let {width, height, borderRadius, circleSizeSmall, padding} = CARDLAYOUT;

    if (isMobile) {
        width = windowWidth - 20;
        height = windowWidth / .7;
    }

    return (
        <svg  className="card-shape" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <defs>
                <mask id="mask_5">
                    <rect width="100%" height="100%" fill="#fff" />
                    <circle cx={circleSizeSmall + padding} cy={circleSizeSmall + padding} r={circleSizeSmall}/>
                    <circle cx={width - (circleSizeSmall + padding)} cy={circleSizeSmall + padding} r={circleSizeSmall}/>
                </mask>
            </defs>

            <rect className="masked" width="100%" height="100%" rx={borderRadius} fill={"#0D619A"} />

            <style jsx>{`
                .masked {
                    mask: url("#mask_5");
                }
            `}</style>
        </svg>
    )
});

const CardContents = React.memo((props) => {
    const {uiStore: {scrollToCard}} = useStore();
    return (
        <div className="card__contents flex--column">
            <h2 className="text-l">
                How would you describe the previous image? 
            </h2>

            <CardFormTags onSubmit={() => scrollToCard(6)}/>

            <style jsx>{`
                .card__contents {
                    padding-top: rem;
                }

                h2, p {
                    color: white;
                }

                @media(max-width: 769px) {

                    .text-l {
                        font-size: 38px;
                    }
                    
                    .card__contents {
                        padding-top: 60px;
                    }
                }
            `}</style>
        </div>
    )
})


export default CardFive