
type Transforms = ({ type: string; from: { x: number; y: number; }; to: { x: number; y: number; }; } | { type: string; from: number; to: number; })[]

export function getCardTransformString(initialPosition: {rotate: number; translate3d: {x: number; y: number;}}, transforms: Transforms, tween: number) {
    let transformStr = ''
    
    const ipString = `rotate(${initialPosition.rotate}deg) translate(${initialPosition.translate3d.x}px,${initialPosition.translate3d.y}px)`

    transforms.forEach(transform => {
        const {to, type} = transform;

        
        switch (type) {
            case "translate3d":
                if (typeof(to) === 'number') return
                // const x = tween * to.x;
                // const y = tween * to.y;
                transformStr += `translate3d(${tween * to.x}px, ${tween * to.y}px, 0px)`
                break;
            case "rotate":
                if (typeof(to) !== 'number') return

                // const deg = ;
                transformStr += ` rotate(${tween * to}deg)`
        }
    });

    return `translate(-50%, -50%) ${ipString} ${transformStr}`
}