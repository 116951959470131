const FormSubmitBtn = ({onClick, canSubmit}: {onClick: (e) => void; canSubmit: boolean;}) => {
    return (
        <div onClick={onClick} className={canSubmit ? "active" : ""}>
            <span className="text-s">Donate</span>

            <style jsx>{`
                div {
                    position: absolute;
                    bottom: 15px;
                    right: 15px;
                    padding: 5px 20px;

                    opacity: 0;
                    cursor: pointer;
                    transition: opacity .2s ease;
                    border-radius: 25px;
                    pointer-events: none;
                    
                }
                span {
                    pointer-events: none;
                    color: white;
                }

                .active {
                    opacity: 1;
                    background: var(--color--offBlack);
                    pointer-events: all;
                }



            `}</style>
        </div> 
    )
}

export default FormSubmitBtn