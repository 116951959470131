import React from 'react'
import { CardStack } from './components/card'
import {Hero, ScrollPage } from './components/general'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Privacy from 'components/general/Privacy';


const App = () => {
    return (
        <Router>
            <div>

                <Switch>
                    <Route path="/privacy">
                        <Privacy/>
                    </Route>
                    <Route path="/">
                        <DesktopLayout/>
                    </Route>
                </Switch>

                <style jsx global>{`
                    :root {
                        --color--bg: #B56435;
                        --color--lightgray: #c4c4c4;
                        --color--gray: #999;
                        --color--offBlack: #222;

                        --color--whiteHover: #f8f8f8;
                        --color--offBlackHover: #2a2a2a;

                        --font--xs: 15px;
                        --font--s: 22px;
                        --font--m: 42px;
                        --font--l: 48px;
                        --font--xl: 72px;

                        --font--xs-mobile: 13px;
                        --font--s-mobile: 17px;
                        --font--m-mobile: 32px;
                        --font--l-mobile: 38px;
                        --font--xl-mobile: 50px;
                    }

                    @font-face {
                        src: url('/assets/fonts/ABCWhyteInktrap-Bold.otf') format("embedded-opentype"), /* IE 9 */
                            url('/assets/fonts/ABCWhyteInktrap-Bold.woff2') format("woff2"),
                            url('/assets/fonts/ABCWhyteInktrap-Bold.woff') format("woff");
                        font-family: 'Inktrap';
                        font-style: normal;
                        font-weight: 700;
                        font-display: block;
                    }

                    @font-face {
                        src: url('/assets/fonts/ABCWhyteInktrap-Regular.otf') format("embedded-opentype"), /* IE 9 */
                            url('/assets/fonts/ABCWhyteInktrap-Regular.woff2') format("woff2"),
                            url('/assets/fonts/ABCWhyteInktrap-Regular.woff') format("woff");
                        font-family: 'Inktrap';
                        font-style: normal;
                        font-weight: 400;
                        font-display: block;
                    }

                    ::selection {
                        background: white;
                        color: black;
                    }

                    textarea::selection, input::selection {
                        background: black;
                        color: white;
                    }

                    * {
                        box-sizing: border-box;
                        line-height: 1.1;
                        font-family: 'Inktrap';
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        text-rendering: geometricPrecision;
                    }

                    html, body {
                        background: var(--color--bg);
                        margin: 0;
                        padding: 0;
                    }

                    h1,h2,h3,h4,h5,h6 {
                        font-weight: 700;
                    }

                    h1,h2,h3,h4,h5,h6, p, li,ul {
                        margin: 0;
                    }

                    a {
                        color: black;
                        text-decoration: none;
                    }

                    p, textarea, .text-s {
                        line-height: 1.28;
                    }

                    .flex {
                        display: flex;
                    }

                    .flex--center {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .flex--space-between {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .flex--center-y {
                        display: flex;
                        align-items: center;
                    }

                    .flex--column {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                    }

                    .flex-stretch {
                        flex-grow: 1;
                    }

                    .text-xs {
                        font-size: var(--font--xs);
                    }

                    .text-s {
                        font-size: var(--font--s);
                    }

                    .text-m {
                        font-size: var(--font--m);
                    }

                    .text-l {
                        font-size: var(--font--l);
                    }

                    .text-xl {
                        font-size: var(--font--xl);
                        line-height: 1;
                    }

                    .uppercase {
                        text-transform: uppercase;
                    }

                    .text-regular {
                        font-weight: 400;
                    }

                    .text-bold {
                        font-weight: 700;
                    }

                    .text-center {
                        text-align: center;
                    }

                    @media (max-width: 769px) {
                        .text-xs {
                            font-size: var(--font--xs-mobile);
                        }

                        .text-s {
                            font-size: var(--font--s-mobile);
                        }

                        .text-m {
                            font-size: var(--font--m-mobile);
                        }

                        .text-l {
                            font-size: var(--font--l-mobile);
                        }

                        .text-xl {
                            font-size: var(--font--xl-mobile);
                            line-height: 1;
                        }

                    }

                    @media (max-width: 320px) {
                        .text-s {
                            font-size: 15px;
                        }
                        .text-m {
                            font-size: 28px;
                        }
                        .text-l {
                            font-size: 34px;
                        }
                        .text-xl {
                            font-size: 44px;
                        }
                    }

                `}</style>
            </div>
        </Router>

        )
    }

    const DesktopLayout = () => {
        return (
            <div>
                <Hero/>
                <CardStack/>
                <ScrollPage/>


                <style jsx>{`
                    div {

                        background: var(--color--bg);
                        animation: ok .4s ease;
                        animation-delay: .15s;
                        animation-fill-mode: forwards;
                        opacity: 0;
                        position: relative;
                    }

                    @keyframes ok {
                        from {
                            opacity: 0
                        }
                        to {
                            opacity: 1;
                        }
                    }
                `}</style>
            </div>
    )
}

export default App
