import React, { useState } from 'react'
import { CARDLAYOUT } from "../../../utils/layout/CardLayout"
import {observer} from 'mobx-react-lite'
import { useStore } from "hooks/useStore";
import { getCardTransformString } from 'utils/layout/getCardTransformString';
import { group } from 'node:console';

const CardFour = observer(() => {
    const {uiStore: {tweens__cardFour, isMobile, incrementCardsMobile, mobile__cardFour__toggled}} = useStore();
    const transforms = CARDLAYOUT.cards.cardFour.tranforms;
    const initialPosition = CARDLAYOUT.cards.cardFour.initialPosition;

    const transformStyle = isMobile ? {} :
    {
        transform: getCardTransformString(initialPosition, transforms, tweens__cardFour)
    };
    return (
        <div onClick={isMobile ? incrementCardsMobile : undefined} style={transformStyle} className={mobile__cardFour__toggled ? "card toggled card--4" : "card card--4"}>
            <CardShape></CardShape>
        </div>
    )
})

const CardShape = React.memo((props) => {
    const {uiStore: {isMobile, windowWidth}} = useStore();
    let {width, height, circleSizeSmall, padding} = CARDLAYOUT;

    let x1, x2, x3, y1, y2, y3;

    if (isMobile) {
        width = windowWidth - 20;
        height = windowWidth / .7;
        x1 = 105; 
        x2 = 25;
        x3 = 105;

        // y1 = 238;
        // y2 = 348;
        // y3 = 456;

        y1 =  127 + 60 + ((height - (127 + 60)) * .2) - 12;
        y2 =  127 + 60 + ((height - (127 + 60)) * .5) - 16;
        y3 =  127 + 60 + ((height - (127 + 60)) * .8) - 20;
    } else {
        x1 = 20; 
        x2 = 220;
        x3 = 60;
        
        y1 = 240;
        y2 = 360;
        y3 = 480;
    }

    return (
        <svg  className="card-shape" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <defs>
                <mask id="mask_4">
                    <rect width="100%" height="100%" fill="#fff" />
                    <circle cx={width - (circleSizeSmall + padding)} cy={circleSizeSmall + padding} r={circleSizeSmall}/>
                    {isMobile ? 
                    <g>
                     <rect x={x1} y={y1} width="230" height="50" rx="30" />
                     <rect x={x2} y={y2} width="230" height="50" rx="30" />
                     <rect x={x3} y={y3} width="230" height="50" rx="30" />
                     </g>
                    :
                    <g>
                    <rect x={x1} y={y1} width="260" height="80" rx="40" />
                    <rect x={x2} y={y2} width="260" height="80" rx="40" />
                    <rect x={x3} y={y3} width="260" height="80" rx="40" />
                    </g>
                    }
                </mask>
            </defs>

            <image className="masked" href="/assets/images/wedding-dress.jpg" width="100%" height="100%" preserveAspectRatio="xMidYMin slice"></image>

            <style jsx>{`
                .masked {
                    mask: url("#mask_4");
                }


                svg {
                    border-radius: 25px;
                }
            `}</style>
        </svg>
    )
});

export default CardFour