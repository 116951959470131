import React, { useState } from 'react'
import { CARDLAYOUT } from "../../../utils/layout/CardLayout"
import {observer} from 'mobx-react-lite'
import { useStore } from "hooks/useStore";
import { SignUpForm } from 'components/general';
import { getCardTransformString } from 'utils/layout/getCardTransformString';

const CardEight = observer(() => {
    const {uiStore: {tweens__cardEight,isMobile, incrementCardsMobile, mobile__cardEight__toggled}} = useStore();
    const transforms = CARDLAYOUT.cards.cardEight.tranforms;
    const initialPosition = CARDLAYOUT.cards.cardEight.initialPosition;

    const transformStyle = isMobile ? {} :
    {
        transform: getCardTransformString(initialPosition, transforms, tweens__cardEight)
    };
    

    return (
        <div onClick={isMobile ? incrementCardsMobile : undefined} style={transformStyle} className={mobile__cardEight__toggled ? "card toggled card--8" : "card card--8"}>
            <CardShape></CardShape>
            <CardContents></CardContents>

        </div>
    )
})


const CardShape = React.memo((props) => {
    let {borderRadius, cards: {cardEight: {height, width, mobileHeight}} } = CARDLAYOUT;
    const {uiStore: {isMobile, windowWidth}} = useStore();

    if (isMobile) {
        width = windowWidth - 20;
        height = mobileHeight;
    }
    return (
        <svg  className="card-shape" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <rect className="masked"  width="100%" height="100%" rx={borderRadius} fill={"#F7D96D"} />
        </svg>
    )
});

const CardContents = React.memo((props) => {
    return (
        <div className="card__contents flex--column">
            <h2 className="text-l uppercase text-center">
                Download the app to help craft a better digital future.
            </h2>

            <SignUpForm/>

            <style jsx>{`

                .card__contents{
                    justify-content:center;
                }

                h2, p {
                    margin-bottom:1rem;
                    color: black;
                }
            `}</style>
        </div>
    )
})

export default CardEight